import shopifySparkV2Defaults from '../shopify-spark-v2/config.js';

const { ignoreFields } = shopifySparkV2Defaults.Widgets.find((w) => w.name === 'FacetPanel');
ignoreFields.push('YearsPanel');

export default {
  includes: ['shopify-spark-v2'],
  ...shopifySparkV2Defaults,
  Widgets: [
    ...shopifySparkV2Defaults.Widgets,
    {
      name: 'YearsPanel',
      type: 'FacetTiles',
      location: {
        insertAfter: 'body.template-collection .subcollections-thumbs.featured-promotions',
        class: 'cm_years-panel__container',
      },
      facetField: 'YearsPanel',
      template: 'facetTiles/yearsTilesContainer',
    },
  ],
};
