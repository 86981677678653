
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(out_of_stock, special_order, i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function scopeOut_of_stockSpecial_order2() {
                    var out_of_stock = !this.stock?.includes('Available');
                    var special_order = this.stock?.includes('Include Special Order');
                    return _createElement('div', {
                        'className': 'product-card layout-1',
                        'data-product-id': 'product-' + this.id,
                        'data-id': this.id
                    }, _createElement('div', { 'className': 'product-image' }, window.themeSettings.show_wishlist === 'true' ? _createElement('a', {
                        'className': 'product_wishlist wishlist',
                        'href': '#',
                        'aria-label': 'wishlist',
                        'data-icon-wishlist': true,
                        'data-product-handle': this.handle,
                        'data-id': this.id,
                        'key': '310'
                    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-heart-fill' }))) : null, _createElement('div', { 'className': 'product_badges' }, window.Convermax.productIsNew(this.published_at) ? _createElement('div', {
                        'className': 'badge new-badge',
                        'key': '664'
                    }, '\n          ', window.themeSettings.locales.new, '\n        ') : null, !out_of_stock && this.on_sale ? _createElement('div', {
                        'className': 'badge sale-badge sale-badge-2',
                        'key': '819'
                    }, '\n          -', this.calcDiscount(this.price, this.compare_at_price), '%\n        ') : null, out_of_stock ? _createElement('div', {
                        'className': 'badge soldOut-badge',
                        'key': '999'
                    }, window.themeSettings.locales.sold_out) : null, special_order ? _createElement('div', {
                        'className': 'badge specialOrder-badge',
                        'key': '1107'
                    }, '\n          ', window.themeSettings.locales.special_order, '\n        ') : null), _createElement('a', {
                        'className': 'product-link ' + (this.image2 ? 'image-swap' : ''),
                        'href': this.url,
                        'aria-label': 'link'
                    }, _createElement('img', {
                        'className': (this.image2 ? 'image-one ' : '') + 'lazyautosizes lazyloaded',
                        'src': this.imageOrDefault(this.resizeImage(this.image)),
                        'data-src': this.image,
                        'data-sizes': 'auto',
                        'alt': this.removeHTML(this.title),
                        'sizes': '285px',
                        'onError': this.onImageError
                    }), this.image2 ? _createElement('img', {
                        'className': 'image-two lazyautosizes lazyloaded',
                        'src': this.resizeImage(this.image2),
                        'data-src': this.image2,
                        'data-sizes': 'auto',
                        'alt': this.removeHTML(this.title),
                        'sizes': '285px',
                        'onError': e => this.onImageError(e, 'image2'),
                        'key': '1663'
                    }) : null), window.themeSettings.quick_view_enabled === 'true' ? _createElement('a', {
                        'className': 'product-quickview',
                        'aria-label': 'quickview',
                        'id': this.handle,
                        'data-quickview': true,
                        'aria-describedby': 'a11y-external-message',
                        'key': '1943'
                    }, '\n        ', window.themeSettings.locales.quick_view, '\n      ') : null, window.themeSettings.show_product_button === 'true' ? _createElement('div', {
                        'className': 'product-action',
                        'key': '2211'
                    }, _createElement('form', {
                        'action': '/cart/add',
                        'method': 'post',
                        'className': 'variants',
                        'id': 'list-product-form-' + this.id,
                        'data-id': 'product-actions-' + this.id,
                        'encType': 'multipart/form-data'
                    }, this.variant_ids.length > 1 ? [_createElement('a', {
                            'className': 'btn product-btn',
                            'href': this.url,
                            'aria-label': 'button',
                            'key': '24841'
                        }, '\n              ', this.add_to_cart_text || (out_of_stock ? window.themeSettings.locales.sold_out : special_order ? window.themeSettings.locales.special_order : window.themeSettings.locales.select_options), '\n            ')] : null, this.variant_ids.length === 1 ? [
                        _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids,
                            'key': '28831'
                        }),
                        _createElement('input', {
                            'type': 'hidden',
                            'name': 'quantity',
                            'value': '1',
                            'key': '28833'
                        }),
                        _createElement('button', {
                            'data-btn-addtocart': true,
                            'className': 'btn product-btn',
                            'type': 'submit',
                            'data-form-id': '#list-product-form-' + this.id,
                            'disabled': out_of_stock,
                            'key': '28835'
                        }, '\n              ', this.add_to_cart_text || (out_of_stock ? window.themeSettings.locales.sold_out : special_order ? window.themeSettings.locales.special_order : window.themeSettings.locales.add_to_cart), '\n            ')
                    ] : null)) : null), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'product-detail' }, window.themeSettings.show_product_vendor === 'true' ? _createElement('div', {
                        'className': 'product-vendor',
                        'key': '3597'
                    }, _createElement('a', Object.assign({}, {
                        'href': this.vendor_url,
                        'title': this.removeHTML(this.vendor)
                    }, { dangerouslySetInnerHTML: { __html: this.vendor } }))) : null, window.themeSettings.show_product_title === 'true' ? _createElement('h4', {
                        'className': 'product-title',
                        'key': '3860'
                    }, _createElement('a', Object.assign({}, {
                        'href': this.url,
                        'aria-label': 'title'
                    }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } }))) : null, window.themeSettings.show_product_reviews === 'true' ? _createElement('div', {
                        'className': 'product-reviews',
                        'key': '4114'
                    }, _createElement('span', {
                        'className': 'shopify-product-reviews-badge',
                        'data-id': this.id
                    })) : null, this.on_sale ? _createElement('div', {
                        'className': 'product-price price--on-sale',
                        'key': '4315'
                    }, _createElement('span', { 'className': 'price-item price-item--regular' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))), _createElement('span', {
                        'className': 'price-item price-item--sale',
                        'data-sale-price': true
                    }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))) : null, !this.on_sale ? _createElement('div', {
                        'className': 'product-price',
                        'key': '4718'
                    }, _createElement('span', { 'className': 'price-item price-item--regular' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))) : null, [this.review_count ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_review-stars',
                                'key': '49360'
                            },
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI1.bind(this, out_of_stock, special_order)),
                            _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                        ]) : null], this.price ? _createElement('p', {
                        'className': 'affirm-as-low-as',
                        'data-amount': this.price * 100,
                        'data-affirm-type': 'logo',
                        'data-affirm-color': 'blue',
                        'key': '5342'
                    }) : null)));
                }
                function scopeColorArrColorImageUrl3(colorOption, colorOptionIndex) {
                    var colorArr = colorOption.split('|');
                    var color = colorArr[0];
                    var image = colorArr[1];
                    var url = colorArr[2];
                    return [_createElement('a', {
                            'className': 'product-color-swatch' + (colorOptionIndex >= 4 ? ' hidden' : ''),
                            'data-product-id': 'product-' + this.id,
                            'data-option-image': image,
                            'title': color,
                            'href': url,
                            'style': {
                                backgroundColor: color,
                                backgroundImage: 'url(' + image + ')'
                            },
                            'key': '56881'
                        }, '\n        ', color, '\n      ')];
                }
                function repeatColorOption4(colorOption, colorOptionIndex, colorArr, color, image, url) {
                    return scopeColorArrColorImageUrl3.apply(this, [
                        colorOption,
                        colorOptionIndex
                    ]);
                }
                return _createElement('div', { 'className': 'halo-item' }, scopeOut_of_stockSpecial_order2.apply(this, []), window.themeSettings.show_product_color_swatch && this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'product-color-swatch-container',
                        'key': '5508'
                    },
                    _map(this.product_colors, repeatColorOption4.bind(this)),
                    this.product_colors.length > 5 ? [_createElement('a', {
                            'className': 'product-color-swatch show-more',
                            'key': '61791'
                        }, '+', this.product_colors.length - 4)] : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related_items__container' }, _createElement('div', { 'className': 'halo-special-collection-layout' }, _createElement('div', { 'className': 'section-header' }, _createElement('h2', {}, 'Related Products'), _createElement('div', { 'className': 'arrow-on-heading' }, [_createElement('svg', {
            'aria-hidden': 'true',
            'focusable': 'false',
            'role': 'presentation',
            'className': 'icon icon-chevron-left btn-arrow btn-arrow-prev',
            'viewBox': '0 0 370.814 370.814',
            'key': '1990'
        }, _createElement('g', {}, _createElement('g', {}, _createElement('polygon', { 'points': '292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401' }))))], [_createElement('svg', {
            'aria-hidden': 'true',
            'focusable': 'false',
            'role': 'presentation',
            'className': 'icon icon-chevron-right btn-arrow btn-arrow-next',
            'viewBox': '0 0 478.448 478.448',
            'key': '5310'
        }, _createElement('g', {}, _createElement('g', {}, _createElement('polygon', { 'points': '131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448 377.954,239.232' }))))]), _createElement('hr', {
        'className': 'section-header-border',
        'aria-hidden': 'true'
    }))), _createElement('div', { 'className': 'cm_SearchResult product-recommendations__items' }, _createElement.apply(this, [
        'div',
        {
            'className': 'halo-row halo-row-custom column-' + (window.Convermax.productRecommendationsSettings ? window.Convermax.productRecommendationsSettings.column : '2') + ' ' + (window.Convermax.productRecommendationsSettings && window.Convermax.productRecommendationsSettings.carousel ? 'collection-carousel' : '') + ' cmRepeater_items',
            'data-slick': window.Convermax.productRecommendationsSettings ? window.Convermax.productRecommendationsSettings.dataSlick : ''
        },
        _map(this.items, repeatItems1.bind(this))
    ])));
}
        export const componentNames = []